@use '@/styles/utils/mixins.scss' as *;

.headerWrapper {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;

  .topNav {
    background-color: $neutral02;
    padding: 1.25rem 0;
    transition: all 0.2s ease-in-out;
    position: relative;
    z-index: 1;

    @include tab() {
      display: none;
    }
  }
  &.fixed {
    .topNav {
      height: 0rem;
      transform: translateY(-200%);
      padding: 0;
      visibility: hidden;
      opacity: 0;
    }
  }
  .contactList {
    display: flex;
    align-items: center;
    margin: 0;
    padding: 0;
    gap: 2.25rem;
    list-style: none;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.018rem;
    text-transform: uppercase;
    color: $primary;

    li {
      display: flex;
      align-items: center;
      gap: 0.75rem;
    }
    a {
      font-weight: 600;
      font-size: 1rem;
      color: $dark;
      &:hover,
      &:focus,
      &:active {
        color: $primary;
      }
    }
  }
  .actionList {
    display: flex;
    align-items: center;
    margin: 0;
    padding: 0;
    gap: 1.875rem;
    list-style: none;
  }

  .mainNav {
    background-color: $white;
    padding: 0.75rem 0;
    position: relative;
    z-index: 2;

    @include tab() {
      padding: 1.5rem 0;
      -webkit-box-shadow: 0rem 0.25rem 0.25rem 0rem rgba($neutral03, 0.5);
      -moz-box-shadow: 0rem 0.25rem 0.25rem 0rem rgba($neutral03, 0.5);
      box-shadow: 0rem 0.25rem 0.25rem 0rem rgba($neutral03, 0.5);
    }
    .menuNavigation {
      display: flex;
      align-items: center;
      list-style: none;
      gap: 2rem;
      justify-content: flex-end;
    }
    .searchBox {
      display: flex;
      border-radius: 1.25rem;
      border: 0.063rem solid $neutral06;
      overflow: hidden;
      align-items: center;
      transition: all 0.2s ease-in-out;
      width: 0;
      max-width: 43.75rem;
      position: absolute;
      right: -100%;
      top: 0.25rem;
      z-index: -1;
      padding: 0.25rem 1rem;
      padding-left: 2rem;
      background-color: $white;

      &.searchOpen {
        width: 100%;
        right: 3.75rem;
        z-index: 99;
      }

      .searchForm {
        width: 100%;
        display: flex;
        align-items: center;
      }

      &:focus-within {
        -webkit-box-shadow: 0rem 0rem 0rem 0.125rem rgba($primary, 0.1);
        -moz-box-shadow: 0rem 0rem 0rem 0.125rem rgba($primary, 0.1);
        box-shadow: 0rem 0rem 0rem 0.125rem rgba($primary, 0.1);
      }

      .searchBoxInput {
        padding: 0;
        border: 0;
        height: auto;
        min-height: auto;
        font-size: 1rem;
        line-height: 1;
        color: $primary;
        font-weight: 500;
        outline: 0;
        &::placeholder {
          color: $neutral06;
          opacity: 1;
        }
        &:focus {
          box-shadow: none;
        }
      }
      .searchBtn {
        padding: 1rem;
        border: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 0 0 auto;
        height: 100%;
        border-radius: 1.25rem;
        transition: all 0.2s ease-in-out;

        &:focus {
          box-shadow: none;
        }
        &:hover,
        &:focus,
        &:active {
          background: $neutral01;
        }
      }
      :global {
        .ant-select.ant-select-single {
          height: auto;
          flex: 1 1 auto;

          .ant-select-selector {
            border-radius: 0;
            .ant-input {
              border-radius: 0;
            }
          }
        }
      }
    }
  }

  .brandLogo {
    width: auto;
    height: 3.5rem;
    @include tab() {
      height: 1.875rem;
    }
  }
  .searchBtn {
    margin-left: -1rem;
    padding: 1rem;
    border-radius: 1.25rem;
    transition: all 0.2s ease-in-out;
    border: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 0 0 auto;
    height: auto;
    &:focus {
      box-shadow: none;
    }
    &:hover,
    &:focus,
    &:active {
      background: $neutral01 !important;
    }
  }
  .desktopNavigation {
    display: block;
    @include tab() {
      display: none;
    }
  }

  .mobileNavigation {
    display: none;
    @include tab() {
      display: block;
    }
  }
  .menuLink {
    padding: 0.5rem 0;
    font-size: 1rem;
    line-height: 1;
    font-weight: 500;
    color: $dark;
    position: relative;

    &::before {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      height: 0.063rem;
      width: 0;
      background-color: $primary;
      transition: width 0.3s;
    }
    &:hover::before,
    &:focus::before,
    &:active::before,
    &Active::before {
      width: 100%;
      transition: width 0.3s;
    }
    &:active,
    &:focus,
    &:hover,
    &Active {
      color: $primary;
    }
  }
}
